import {
  cifFr,
  cifGb,
  cifEs,
  cifGe,
  cifPt,
  cifIt,
  cifCa,
  cifGr,
  cifUs,
  cifCy,
  cifTr,
  cifHu,
  cifBy,
  cifUa,
  cifRu,
  cifDe,
  cifIl,
  cifTh,
  cifBg,
  cifCr,
  cifLv,
  cifPl,
  cifAe,
  cifHr,
  cifLt,
  cifBb,
  cifFi,
  cifCz,
  cifEg,
  cifZa,
  cifAt,
  cifMa,
  cifAl,
  cifGh,
  cifSl,
  cifCh,
  cifId,
  cifNl,
  cifAg,
  cifRo,
  cifDo,
  cifSi,
  cifMc,
  cifCv,
  cifGy,
  cifMt,
  cifIe,
  cifMe,
} from "@coreui/icons";

const FlagsSvgs = {
  "United Kingdom": cifGb,
  Spain: cifEs,
  France: cifFr,
  Germany: cifDe,
  Portugal: cifPt,
  Italy: cifIt,
  Canada: cifCa,
  Greece: cifGr,
  USA: cifUs,
  Cyprus: cifCy,
  Turkey: cifTr,
  Israel: cifIl,
  Hungary: cifHu,
  Belarus: cifBy,
  Ukraine: cifUa,
  Russia: cifRu,
  Montenegro: cifMe,
  Georgia: cifGe,
  Thailand: cifTh,
  Bulgaria: cifBg,
  "Uae / Dubai": cifAe,
  Croatia: cifHr,
  Latvia: cifLv,
  Poland: cifPl,
  UAE: cifAe,
  Lithuania: cifLt,
  Ireland: cifIe,
  Barbados: cifBb,
  Finland: cifFi,
  "Czech Republic": cifCz,
  Egypt: cifEg,
  Morocco: cifMa,
  "South Africa": cifZa,
  Austria: cifAt,
  Malta: cifMt,
  Albania: cifAl,
  Ghana: cifGh,
  "St Lucia": cifSl,
  Indonesia: cifId,
  Switzerland: cifCh,
  Netherlands: cifNl,
  "Antigua And Barbuda": cifAg,
  Gibraltar: cifGy,
  Romania: cifRo,
  "Dominican Republic": cifDo,
  Slovenia: cifSi,
  Monaco: cifMc,
  "United States": cifUs,
  "Cape Verde": cifCv,
  "Costa Rica": cifCr,
};

export default FlagsSvgs;
