import React from "react";
import { useSelector } from "react-redux";
import ListingsCounts from "../../data_components/ListingsCounts";
import { numberWithCommas } from "../../utils/Numbers";
import SampleDownloadButton from "../../components/SampleDownloadButton";

export default function Stats(props) {
  const countriesListingsCounts = useSelector(
    (state) => state.countriesListingsCounts
  );

  return (
    <>
      <ListingsCounts />
      <section className="stats" {...props}>
        <div className="container text-center">
          <div className="row align-items-center text-center">
            <div className="col-md-12">
              <h1>Listings stats</h1>
              {countriesListingsCounts ? (
                <>
                  <p>Amount of listings per country in the last 30 days:</p>
                  <table className="table table-borderless table-hover table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Country</th>
                        <th scope="col">For sale listings</th>
                        <th scope="col">For rent listings</th>
                        <th scope="col">Download sample</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(countriesListingsCounts)
                        .slice(0, 10)
                        .map((x) => {
                          return (
                            <tr key={x}>
                              <td>{x}</td>
                              <td>
                                {numberWithCommas(
                                  countriesListingsCounts[x].forsale
                                )}
                              </td>
                              <td>
                                {numberWithCommas(
                                  countriesListingsCounts[x].rent
                                )}
                              </td>
                              <td>
                                <SampleDownloadButton
                                  screen={"Main"}
                                  type={"country"}
                                  entityName={x}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </>
              ) : (
                <p>Loading data...</p>
              )}
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
    </>
  );
}
